.container {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  margin: 20px 20px 20px 0px;
  padding: 20px;
  max-width: calc((((100% - 192px) / 9) * 6) + 120px + 0px);
}

.valid {
  border: 2px solid #f5f5f5;
}

.invalid {
  border: 2px solid red;
}
